import React from 'react';

import Layout from '../components/layout/layout';
import HeaderExpansion from '../components/layout/header-expansion';
import Button from '../components/button';

const NotFound = () => (
    <Layout contactBanner={ false } location="404" metadata={ { lang: 'en' } }>
        <HeaderExpansion tagline="404" />
        <div className="NotFoundPage__container">
            <span className="NotFoundPage__header">Oops</span>
            <span className="NotFoundPage__subheader">This page is not found...</span>
            <span className="NotFoundPage__back-text">Go back to home</span>
            <Button buttonType="secondary" to="/">Home</Button>
        </div>
    </Layout>
);

export default NotFound;
